import { Injectable } from '@angular/core';
import { Space } from '@memberspot/shared/model/community';
import { of } from 'rxjs';

import { SpacesStore } from './state/spaces.store';

@Injectable({
  providedIn: 'root',
})
export class SpaceSyncService {
  constructor(private store: SpacesStore) {}

  saveSpaces(spaces: Space[]) {
    this.store.set(spaces);

    return of(spaces);
  }

  updateInStore(space: Partial<Space> & { id: string }) {
    this.store.upsert(space.id, space);
  }

  updateMany(upt: (Partial<Space> & { id: string })[]) {
    upt.forEach((u) => this.store.update(u.id, u));
  }

  removeFromStore(spaceId: string) {
    this.store.remove(spaceId);
  }

  upsertItemInStore(space: Space) {
    this.store.upsert(space.id, space);
  }
}
